.modal-body {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 100;
    width: 100vw;
    height: 100vh;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
}

.card-modal-body {
    background-color: rgb(250, 250, 250);
    align-items: flex-start;
    margin-left: 20vw;
    width: 60vw;
    flex-wrap: wrap;
    border-radius: 20px;
    border: solid 1px rgb(170, 170, 170);
    padding-bottom: 10px;
}

.card-modal-full {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    text-align: center;
    width: 100%;
    justify-content: space-between;
}

.button-close {
    border: none;
    border-radius: 7px;
    background-color: #E11;
    color: #FFF;
    padding: 5px;
    width: 40px;
}

.card-modal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.card-image-min {
    height: 100px;
    width: 100px;
}

.card-image-div {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 102;
    height: 100vh;
    width: 100vw;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
}

.card-image-view {
    height: 60vh;
    width: auto;
    border: solid;
    border-width: 10px;
    border-color:rgba(0,0,0,0.5);
    border-radius: 10px;
}