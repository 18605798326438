.content-container {
    max-width: 1140px;
    margin: 0 auto;
    margin-top: 82px;
    margin-bottom: 82px;
}

.content-container .title {
    color: #000;
    font-size: 42px;
    font-weight: 700;
    line-height: 60px;
    text-align: center;
    padding-top: 50px;
}

.content-container section {
    margin-top: 90px;
}

.content-container section p {
    font-size: 16px;
}

.drivers-container .drivers-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 24px;
}

.drivers-container .drivers-list .card {
    background-color: #fff;
    align-items: flex-start;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.drivers-container .drivers-list .card .driver-info {
    display: flex;
    flex-direction: column;
}

.drivers-container .drivers-list .card strong {
    font-weight: bolder;
}

.drivers-container .drivers-list .card .shipping-info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
}