.report-select {
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
    background-color: #EEF;
    max-width: 50%;
}

.report-filters {
    border-radius: 10px;
    background-color: #836FFF;
    padding: 10px;
}

.report-period {
    color:#FFF;
    background-color: #1E90FF;
    border-width: 1px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}