.modal-alert-body{
    z-index: 999;
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
}

.card-modal-alert-body{
    background-color: rgb(240,240,250);
    align-items: flex-start;
    width: 60vw;
    flex-wrap: wrap;
}

.card-modal-alert-full{
    width: 100%;
}